import React, { useState, useContext }  from "react";
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,
    FunctionField,
    Filter,
    SelectInput,
    SelectArrayInput,
    TextInput,
    useRecordContext,
    ShowButton,
    SimpleForm,
    useRefresh,
} from 'react-admin';
import {
    Backdrop,
    CircularProgress
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { UserContext } from "../auth/UserContext";
import axios from '../axiosConfig';
import {VideoRowStyle} from "../utils/CustomRowStyle";
import StatusField from "./StatusField";
import PublishedDateField from "./PublishedDateField";
import ChannelField from "./ChannelField";
import InfoField from "./InfoField";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Dialog,
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    ButtonGroup,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useConstants from '../utils/useConstants';

const ListVideo = (props) =>{
    const authUser = useContext(UserContext);
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [newImageUrl, setNewImageUrl] = useState('');
    const {
        videoStatusArray, 
        videoInfoArray,
        teamArray,
        userArray,
        channelArray
    } = useConstants();

    // Update Thumbnail
    const [isUpdateImages, setIsUpdateImages] = useState(false);
    const [videoActive, setVideoActive] = useState(null);
    const [typeImage, setTypeImage] = useState(null);

    function extractDriveId(url, type) {
        if(!url || url.trim() === '') return null;
        // Sử dụng Regular Expression để tìm ID từ link Google Drive
        const regex = type === 'file'? /\/d\/([a-zA-Z0-9_-]+)/ : /\/drive\/folders\/([a-zA-Z0-9_-]+)/;
        const match = url.match(regex);
    
        // Kiểm tra xem có match không và trả về ID
        if (match && match[1]) {
            return match[1];
        } else {
            return null; // Trả về null nếu không tìm thấy ID
        }
    }

    const VideoUrl = ({ source }) => {
        const record = useRecordContext(); 
        const canEdit = record.team.managers.includes(authUser.id);

        let videoId = null;
        if(record.type){
            videoId = (record.job.jobInfo && record.job.jobInfo.video)? record.job.jobInfo.video :null;
        } else {videoId = record.extraInfo.videoId}

        return <>
            {videoId?<PlayArrowIcon  onClick={()=>window.open(`https://drive.google.com/file/d/${videoId}/view?usp=drive_link`, "_blank")} style={{ color: 'blue',cursor:'pointer'}}/>:<span>No Video</span>}
            {canEdit && !record.type && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleEditVideoClick(record)}> Edit </span>}
        </>     
    };

    const DirectoryField = ({ source }) => {
        const record = useRecordContext(); 
        const canEdit = record.team.managers.includes(authUser.id);
        return <>
            <a rel="noreferrer" target="_blank" href={`https://drive.google.com/drive/folders/${record.directory}`}>Directory</a>
            {canEdit && !record.type && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleEditDirectoryClick(record)}> Edit </span>}
        </>
    }
    
    const ImageRenderer = ({ source }) => {
        const record = useRecordContext(); 
        const canEdit = record.team.managers.includes(authUser.id);
        let data = null
        if(record.type){
            data = source==='thumbnail'? record.job.jobInfo.thumbnails : record.job.jobInfo.idea;
        } else {
            data = source==='thumbnail'?  record.extraInfo.thumbnails: record.extraInfo.ideas;
        }
        if(!data) {return <><span>No Image</span>{canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record, source)}> Edit </span>}</>}
        if(data.length < 1 || !data[0].id) return <><img src="/images/no-photo.jpg"  height="72" alt ="not found"/>{canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record, source)}> Update </span>}</>;
        return (
            <div>
                {data.map((item, index) => <img 
                    key={index} 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" 
                    height="72" 
                    loading="lazy"  
                    style={{ marginRight: '10px',  cursor: 'pointer'  }}
                    onClick={()=>window.open(`https://drive.google.com/file/d/${item.id}/view`, "_blank")}
                /> )}
                {canEdit && <span style={{ color: 'blue',cursor:'pointer'}} onClick={() =>handleUpdateImagesClick(record,source)}> Edit </span>}
            </div>   
        )     
    };

    const DeleteButton = () => {
        const record = useRecordContext(); 
        if(record.type) return null;
        return (
            <IconButton 
                color="secondary"
                onClick={() => {
                    const confirm = window.confirm("Are you sure you want to delete this video?");
                    if (confirm) {
                        setIsLoading(true);
                        axios.delete(`${process.env.REACT_APP_API_URL}api/video/${record.id}`).then(()=>{
                            refresh();
                            setIsLoading(false);                            
                        });
                    }
                }}
            >
                <DeleteIcon />
            </IconButton>
        );
    }

    const VideoFilter = (props) => {
        return (
            <Filter {...props}>
                <TextInput label="Search" source="name"  alwaysOn/>
                <SelectArrayInput 
                    label="Status" 
                    source="status" 
                    choices={videoStatusArray} 
                    optionValue="id"
                    alwaysOn
                />
                <SelectArrayInput 
                    label="Info" 
                    source="info" 
                    choices={videoInfoArray} 
                    optionValue="id"
                    alwaysOn
                />   
                <SelectInput label="Team" source="teamId" choices={teamArray}  alwaysOn/>
                <SelectInput label="Channel" source="channelId" choices={channelArray}  alwaysOn/>
                <SelectInput 
                    label="Creator" 
                    source="staffId" 
                    choices={userArray} 
                    optionValue="id"
                    optionText={(choice) => `${choice.firstName} ${choice.lastName}`}
                    alwaysOn
                />                
            </Filter>
        )
    };

    const handleEditVideoClick = (video) => {
        const inputUrl = prompt("Please enter URL of Video", video.extraInfo.videoId?video.extraInfo.videoId:'');
        const newId = extractDriveId(inputUrl, 'file');
        if(newId && newId !== video.extraInfo.videoId){
            setIsLoading(true);
            axios.put(`${process.env.REACT_APP_API_URL}api/video/videoId/${video.id}`, { videoId:newId}).then(()=>{
                setIsLoading(false);
                refresh();
            });
        }

    }

    const handleUpdateImagesClick = (record, type) => {
        setTypeImage(type);
        setVideoActive(record);
        let currentImages = [];
        if(record.type){
            currentImages = type === 'thumbnail' ? record.job.jobInfo.thumbnails : record.job.jobInfo.ideas;
        } else {
            currentImages = (type === 'thumbnail') ? record.extraInfo.thumbnails : record.extraInfo.ideas;
        }
        setImages(currentImages || []); // Cập nhật state images với giá trị mảng images hiện tại
        setIsUpdateImages(true);
    }

    const handleEditDirectoryClick = (video) => {
        const newDir = prompt("Please enter URL", video.directory);
        if(newDir && newDir !== video.directory){
            setIsLoading(true);
            axios.put(`${process.env.REACT_APP_API_URL}api/video/dir/${video.id}`, { directory:newDir}).then(()=>{
                setIsLoading(false);
                refresh();
            });
        }

    }

    const handleRemoveImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index); // Xóa hình tại index chỉ định
        setImages(updatedImages);
    };

    const handleAddImage = () => {
        const imageId = extractDriveId(newImageUrl, 'file');
        if (imageId) {
            setImages([...images, { id: imageId }]);
            setNewImageUrl(''); // Clear input sau khi thêm
        } else {
            alert('Invalid Google Drive URL');
        }
    };

    const UpdateImages = async() => {
        try{
            setIsLoading(true);
            console.log(images);
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/images/${videoActive.id}`, { images:images, type:typeImage});
        } catch (error) {
            console.error(error);
        } finally {
            refresh();
            setIsLoading(false);
            handleCloseUpdateImages();
        }
    }
    
    const handleCloseUpdateImages = () => {
        setIsUpdateImages(false);
        setVideoActive(null);
        setTypeImage(null);
        setImages([]);
        setNewImageUrl('');
    }

    return (
    <List {...props}  filters={<VideoFilter />}  exporter={false} sort={{ field: 'id', order: 'DESC' }}  >  
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <Dialog open={isUpdateImages} onClose={handleCloseUpdateImages} scroll="paper">
            <DialogTitle id="scroll-dialog-title">Edit {typeImage}:</DialogTitle>
            <DialogContent >
                {videoActive && <SimpleForm toolbar={false}>
                    <div style={{ marginBottom: '16px' }}>
                        {/* Hiển thị hình ảnh hiện tại với nút xóa */}
                        {images.map((image, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <img 
                                    src={`https://drive.google.com/thumbnail?id=${image.id}`} 
                                    alt="img from GG" 
                                    height="72" 
                                    style={{ marginRight: '10px', cursor: 'pointer' }} 
                                />
                                <IconButton onClick={() => handleRemoveImage(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </div>                            
                        ))}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextInput 
                            source="id" 
                            value={newImageUrl}
                            label="Images Id on Google Drive" 
                            onChange={(e) => setNewImageUrl(e.target.value)}
                            fullWidth 
                        /> 
                        <AddCircleIcon onClick={handleAddImage} style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }} />
                    </div>
                </SimpleForm>}
            </DialogContent>
            <DialogActions>
                <ButtonGroup variant="outline" color="primary" aria-label="contained primary button group">
                    <Button onClick={handleCloseUpdateImages} color="primary">Cancel</Button>
                    <Button onClick={()=>UpdateImages()} color="primary">Save</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
        <Datagrid rowStyle={VideoRowStyle} bulkActionButtons={false} >
            <TextField label="Title" source="name" sortable />
            <VideoUrl label="Video" source="type" sortable={false}/>
            <ImageRenderer source="thumbnail" label="Thumbnail" sortable={false}  />
            <ImageRenderer source="idea" label="Idea" sortable={false}  />
            <ReferenceField source="teamId" reference="team" label="Team" sortable >
                <TextField source="name" />
            </ReferenceField>
            <ChannelField source="channelId" setLoading={setIsLoading} sortable/>
            <DirectoryField source="directory" setLoading={setIsLoading} sortable/>     
            <PublishedDateField source="publishedDate" setLoading={setIsLoading} sortable/>
            <StatusField label="Video Status" source="status" setLoading={setIsLoading} allStatus={videoStatusArray} sortable/>
            <InfoField label="Video Info" source="status" setLoading={setIsLoading} allInfo={videoInfoArray} sortable/>
            <ReferenceField source="staffId" reference="user" sortable label="Creator">  
                <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`}  />
            </ReferenceField>
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
    )
};

export default ListVideo;